import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp';

const Contact: React.FC<IconBasePropsWithColor> = ({
  width = 24,
  height = 24,
  color = colors.lightGray,
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 22.0001C11.8274 22.0001 11.6578 21.9554 11.5077 21.8704C11.3575 21.7854 11.2319 21.663 11.143 21.5151L9.033 18.0001H6.57C5.36771 18.0078 4.21142 17.5384 3.35472 16.6949C2.49802 15.8513 2.01083 14.7024 2 13.5001V6.5001C2.01057 5.29775 2.49767 4.14869 3.35443 3.30506C4.21119 2.46142 5.36763 1.99211 6.57 2.0001H17.43C18.6324 1.99211 19.7888 2.46142 20.6456 3.30506C21.5023 4.14869 21.9894 5.29775 22 6.5001V13.5001C21.9892 14.7024 21.502 15.8513 20.6453 16.6949C19.7886 17.5384 18.6323 18.0078 17.43 18.0001H14.967L12.857 21.5151C12.7681 21.663 12.6425 21.7854 12.4923 21.8704C12.3422 21.9554 12.1726 22.0001 12 22.0001ZM6.57 4.0001C5.89789 3.99155 5.24984 4.24997 4.76803 4.71865C4.28622 5.18734 4.01001 5.82801 4 6.5001V13.5001C4.01001 14.1722 4.28622 14.8129 4.76803 15.2815C5.24984 15.7502 5.89789 16.0086 6.57 16.0001H9.6C9.77255 16.0001 9.94216 16.0448 10.0923 16.1298C10.2425 16.2148 10.3681 16.3372 10.457 16.4851L12 19.0571L13.543 16.4851C13.6319 16.3372 13.7575 16.2148 13.9077 16.1298C14.0578 16.0448 14.2274 16.0001 14.4 16.0001H17.43C18.1021 16.0086 18.7502 15.7502 19.232 15.2815C19.7138 14.8129 19.99 14.1722 20 13.5001V6.5001C19.99 5.82801 19.7138 5.18734 19.232 4.71865C18.7502 4.24997 18.1021 3.99155 17.43 4.0001H6.57Z"
        fill={color}
      />
      <path
        d="M8 11C8.55228 11 9 10.5523 9 10C9 9.44772 8.55228 9 8 9C7.44772 9 7 9.44772 7 10C7 10.5523 7.44772 11 8 11Z"
        fill={color}
      />
      <path
        d="M12 11C12.5523 11 13 10.5523 13 10C13 9.44772 12.5523 9 12 9C11.4477 9 11 9.44772 11 10C11 10.5523 11.4477 11 12 11Z"
        fill={color}
      />
      <path
        d="M16 11C16.5523 11 17 10.5523 17 10C17 9.44772 16.5523 9 16 9C15.4477 9 15 9.44772 15 10C15 10.5523 15.4477 11 16 11Z"
        fill={color}
      />
    </svg>
  );
};

export default Contact;
